import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function LinkedIn({ size, color, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M4.98487 7.19692C6.19327 7.19692 7.17287 6.21732 7.17287 5.00892C7.17287 3.80052 6.19327 2.82092 4.98487 2.82092C3.77648 2.82092 2.79688 3.80052 2.79688 5.00892C2.79688 6.21732 3.77648 7.19692 4.98487 7.19692Z"
        fill={color}
      />
      <BaseIconPathStyle
        d="M9.23917 8.85493V20.9939H13.0082V14.9909C13.0082 13.4069 13.3062 11.8729 15.2702 11.8729C17.2072 11.8729 17.2312 13.6839 17.2312 15.0909V20.9949H21.0022V14.3379C21.0022 11.0679 20.2982 8.55493 16.4762 8.55493C14.6412 8.55493 13.4112 9.56193 12.9082 10.5149H12.8572V8.85493H9.23917V8.85493ZM3.09717 8.85493H6.87217V20.9939H3.09717V8.85493Z"
        fill={color}
      />
    </BaseSvgStyle>
  );
}

export default LinkedIn;
